import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./routes/Home";
import Code from "./routes/Code";
import NoCode from "./routes/NoCode";
import AdminCode from "./routes/AdminCode";
import AdminNoCode from "./routes/AdminNoCode";
import Login from "./routes/Login";
import PrivateRoute from "./components/auth/PrivateRoute";
import Leads from "./routes/Leads";
import Renault from "./routes/Renault";
import AdminRenault from "./routes/AdminRenault";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Code />} />
        <Route path="/code" element={<Code />} />
        <Route path="/nocode" element={<NoCode />} />
        <Route path="/renault" element={<Renault />} />
        {/* Admin Pages */}
        <Route path="/admin" element={<Navigate to="/admin/code" />} />
        <Route element={<PrivateRoute type={"code"} />}>
          <Route path="/admin/code" element={<AdminCode />} />
        </Route>
        <Route element={<PrivateRoute type={"nocode"} />}>
          <Route path="/admin/nocode" element={<AdminNoCode />} />
        </Route>
        <Route element={<PrivateRoute type={"renault"} />}>
          <Route path="/admin/renault" element={<AdminRenault />} />
        </Route>
        <Route element={<PrivateRoute type={"common"} />}>
          <Route path="/admin/leads" element={<Leads />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
