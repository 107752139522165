import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { queryKey } from "../utils/queryKey";
import {
  createTableApi,
  deleteTableApi,
  getTablesApi,
  updateTableApi,
  updateTableOrderApi,
} from "../apis/table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createQuotesApi } from "../apis/quotes";
import CodeQuotes from "./CodeQuotes";
import QuotesDocs from "./QuotesDocs";
import { FaXmark } from "react-icons/fa6";
import { getPriceRangesApi } from "../apis/priceRange";

const Container = styled.div``;
const Contents = styled.div``;
const Table = styled.div`
  margin-bottom: 3rem;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  h2 {
    font-size: ${(props) => props.theme.bigFontSize};
    font-weight: 600;
    margin-right: 0.5rem;
  }
  .modify,
  .delete {
    ${(props) => props.theme.buttonFormat}
  }
  .buttons {
    display: flex;
    min-width: max-content;
  }
  button.delete {
    margin-left: 0.3rem;
  }
`;
const Footer = styled.footer`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${(props) => props.theme.keyColor};
  border-radius: 3rem;
  padding: 1rem 2rem;
  width: 900px;
  left: 0;
  right: 0;
  bottom: 0.5rem;
  margin: auto;
  background-color: #fff;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (max-width: 780px) {
    border-radius: 1rem;
    width: 98%;
    padding: 1rem;
  }
  h2.title {
    font-size: ${(props) => props.theme.titleFontSize};
    font-weight: 600;
    margin-bottom: 0.7rem;
  }
  strong {
    font-size: ${(props) => props.theme.titleFontSize};
    font-weight: 600;
    margin-right: 0.2rem;
  }
  .description {
    font-size: ${(props) => props.theme.descriptionFontSize};
    display: flex;
    align-items: flex-end;
    .desc {
      opacity: 0.6;
      margin-left: 0.1rem;
    }
  }
  button.showQuotes {
    ${(props) => props.theme.buttonFormat}
    border-radius: 2rem;
    padding: 0.8rem 1rem;
    &:hover {
      scale: 1;
    }
    @media screen and (max-width: 780px) {
      border-radius: 0.5rem;
      padding: 0.5rem 0.8rem;
    }
  }
`;
const FormModal = styled.div`
  padding: 2rem;
  background-color: #fff;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    align-items: center;
    min-width: 18rem;
    h1 {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
    .icon {
      background-color: transparent;
      border: none;
      cursor: pointer;
      scale: 1.5;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    input {
      ${(props) => props.theme.inputFormat}
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .btnGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    button {
      ${(props) => props.theme.buttonFormat}
    }
  }
`;
const Quote = styled.div`
  border-radius: 1.8rem;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
  padding: 1.5rem 3rem;
  font-size: ${(props) => props.theme.descriptionFontSize};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 6rem;
  @media screen and (max-width: 780px) {
    padding: 0.5rem;
    height: 4rem;
    border-radius: 0.5rem;
  }
  textarea {
    height: 100%;
    border: none;
    overflow: scroll;
    background-color: transparent;
  }
  &.active {
    background-color: ${(props) => props.theme.keyColor};
    transition: 0.3s;
    textarea {
      color: #fff;
    }
  }
`;

function CodeContents() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  // useQuery
  const { data: tables } = useQuery([queryKey.tableKey.getTable], () =>
    getTablesApi("code")
  );
  // console.log({ tables });

  const [btnActive, setBtnActive] = useState([]);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    console.log({ btnActive, selectedQuotes, totalPrice });
  }, [btnActive, selectedQuotes, totalPrice]);

  // 모달 관련
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // width: `40rem`,
      // height: `50vh`,
      padding: 0,
      border: "none",
      backgroundColor: "rgb(245,243,243)",
      overflow: "auto",
      maxHeight: "95%",
      overflowY: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    },
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    if (name === "" || email === "" || phone === "") {
      alert("정보를 입력해주세요.");
      return;
    }
    setIsOpen(true);
    setFormModalOpen(false);
  };
  const closeModal = () => {
    setIsOpen(false);
    setName("");
    setEmail("");
    setPhone("");
    document.body.style.overflow = "auto";
  };

  // 사용자 정보 받는 모달
  const [formModalOpen, setFormModalOpen] = useState(false);
  const openFormModal = () => {
    setFormModalOpen(true);
  };
  const closeFormModal = () => {
    setFormModalOpen(false);
    setName("");
    setEmail("");
    setPhone("");
    document.body.style.overflow = "auto";
  };

  const { data: priceRange } = useQuery("getPriceRange", () =>
    getPriceRangesApi({ type: "code" })
  );
  console.log({ priceRange });

  let requiredTime = 0;
  priceRange?.forEach((range) => {
    if (totalPrice === 0) {
      requiredTime = 0;
    } else if (totalPrice >= range.price) {
      requiredTime = range.requiredTime;
    }
  });

  const [plusAlpha, setPlusAlpha] = useState("");

  return (
    <React.Fragment>
      <Container>
        <Contents>
          {tables?.map((table) => (
            <Table key={table.id}>
              <Title>
                <h2>{table.title}</h2>
              </Title>
              <CodeQuotes
                table={table}
                btnActive={btnActive}
                setBtnActive={setBtnActive}
                selectedQuotes={selectedQuotes}
                setSelectedQuotes={setSelectedQuotes}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
              />
            </Table>
          ))}
          <Table>
            <Title>
              <h2>기타</h2>
            </Title>
            <Quote className={plusAlpha ? "active" : ""}>
              <textarea
                type="text"
                placeholder="요청하실 개발 내용을 입력해주세요."
                onChange={(e) => {
                  setPlusAlpha(e.target.value);
                }}
              />
            </Quote>
          </Table>
        </Contents>
        <Footer>
          <div className="left">
            <h2 className="title">예상 견적</h2>
            <span className="description">
              <strong>{totalPrice}</strong>만 원{plusAlpha && " + ɑ"}
              <p className="desc">
                (예상 기간{" "}
                {requiredTime === "need" ? (
                  <>상담 필요</>
                ) : (
                  <>{requiredTime}개월</>
                )}
                )
              </p>
            </span>
          </div>
          <div className="right">
            <button className="showQuotes" onClick={openFormModal}>
              견적서 보기
            </button>
          </div>
        </Footer>
      </Container>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
        // overlayClassName="overlay"
        // className="modal"
      >
        <div id="modal-scrollable" className="modal-scrollable">
          <QuotesDocs
            selectedQuotes={selectedQuotes}
            totalPrice={totalPrice}
            closeModal={closeModal}
            type="code"
            name={name}
            email={email}
            phone={phone}
            plusAlpha={plusAlpha}
          />
        </div>
      </Modal>
      <Modal
        isOpen={formModalOpen}
        style={customStyles}
        onRequestClose={closeFormModal}
      >
        <FormModal>
          <div className="header">
            <h1>정보를 입력해주세요</h1>
            <FaXmark className="icon" onClick={closeFormModal} />
          </div>
          <div className="info">
            <input
              type="text"
              placeholder="수신인명"
              autoComplete="off"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <input
              type="email"
              placeholder="이메일"
              autoComplete="off"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <input
              type="text"
              placeholder="연락처"
              autoComplete="off"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </div>
          <div className="btnGroup">
            <button onClick={openModal}>견적서 보기</button>
          </div>
        </FormModal>
      </Modal>
    </React.Fragment>
  );
}

export default CodeContents;
