import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { queryKey } from "../../utils/queryKey";
import {
  createTableApi,
  deleteTableApi,
  getTablesApi,
  updateTableApi,
  updateTableOrderApi,
} from "../../apis/table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createQuotesApi } from "../../apis/quotes";
import RenaultQuotes from "./RenaultQuotesAdmin";

const Container = styled.div``;
const Contents = styled.div``;
const Table = styled.div`
  margin-bottom: 3rem;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  h2 {
    font-size: ${(props) => props.theme.bigFontSize};
    font-weight: 600;
    margin-right: 0.5rem;
  }
  .modify,
  .delete {
    ${(props) => props.theme.buttonFormat}
  }
  .buttons {
    display: flex;
    min-width: max-content;
  }
  button.delete {
    margin-left: 0.3rem;
  }
`;
const OpenButton = styled.div`
  button {
    ${(props) => props.theme.buttonFormat}
  }
`;
const ContentsForm = styled.form`
  padding: 1rem;
  background-color: ${(props) => props.theme.bgColor};
  .inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  input,
  textarea {
    ${(props) => props.theme.inputFormat}
  }
  textarea {
    height: 200px;
    resize: none;
  }
  button {
    ${(props) => props.theme.buttonFormat}
    &:hover {
      scale: 1.05;
    }
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    :hover {
      scale: 1.05;
    }
  }
`;
const UpOrDownButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.3rem;
  button {
    scale: 0.8;
    cursor: pointer;
  }
`;

function RenaultContentsAdmin() {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: `50vw`,
      // height: `50vh`,
      padding: 0,
      borderColor: "#000",
      backgroundColor: "rgb(245,243,243)",
    },
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    setValue("title", "");
  };

  const [tableIsOpen, setTableIsOpen] = useState(false);
  const openTableModal = () => {
    setTableIsOpen(true);
  };
  const closeTableModal = () => {
    setTableIsOpen(false);
    setValue("title", "");
  };

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  // useQuery
  const {
    data: tables,
    isLoading,
    refetch,
  } = useQuery([queryKey.tableKey.getTable], () => getTablesApi("renault"));
  console.log({ tables });

  const { mutate: createTable } = useMutation(createTableApi, {
    onSuccess: () => {
      refetch();
      closeModal();
      setValue("title", "");
    },
  });
  const onSubmitTable = (inputData) => {
    // console.log({ inputData });
    // console.log(inputData.title);
    console.log("onSubmitTable");
    createTable({
      type: "renault",
      title: inputData.title,
    });
    setValue("title", "");
  };
  const onError = (error, e) => {
    console.log(error, e);
    alert("내용을 입력해주세요");
  };

  // 테이블 수정 및 제거
  const { mutate: updateTable } = useMutation(updateTableApi, {
    onSuccess: () => {
      refetch();
      closeTableModal();
    },
  });
  const { mutate: deleteTable } = useMutation(deleteTableApi, {
    onSuccess: () => {
      refetch();
      closeTableModal();
    },
  });

  const onClickModify = (title, id) => {
    setValue("title", title);
    setValue("id", id);
    openTableModal();
  };
  const onSubmitTableUpdate = (inputData) => {
    console.log({ inputData });
    console.log("onSubmitTableUpdate");
    updateTable({
      type: "renault",
      title: inputData.title,
      id: inputData.id,
    });
  };

  // 순서 변경
  const { mutate: upOrDownTable } = useMutation(updateTableOrderApi, {
    onSuccess: () => {
      refetch();
    },
  });
  const onClickUp = (id) => {
    upOrDownTable({
      type: "renault",
      id: id,
      upOrDown: "up",
    });
  };
  const onClickDown = (id) => {
    upOrDownTable({
      type: "renault",
      id: id,
      upOrDown: "down",
    });
  };

  return (
    <React.Fragment>
      <Container>
        <Contents>
          {tables?.map((table) => (
            <Table key={table.id}>
              <Title>
                <h2>{table.title}</h2>
                <div className="buttons">
                  <button
                    className="modify"
                    onClick={() => {
                      onClickModify(table.title, table.id);
                    }}
                  >
                    수정
                  </button>
                  <button
                    className="delete"
                    onClick={() => {
                      if (
                        window.confirm(
                          `${table.order}번째 헤더를 삭제하시겠습니까?`
                        )
                      ) {
                        deleteTable({ type: "renault", id: table.id });
                      }
                    }}
                  >
                    삭제
                  </button>
                  <UpOrDownButtons>
                    <button
                      onClick={() => {
                        onClickDown(table.id);
                      }}
                      disabled={table.order === 1}
                    >
                      ▲
                    </button>
                    <button
                      onClick={() => {
                        onClickUp(table.id);
                      }}
                      disabled={table.order === tables.length}
                    >
                      ▼
                    </button>
                  </UpOrDownButtons>
                </div>
              </Title>
              <RenaultQuotes table={table} customStyles={customStyles} />
            </Table>
          ))}
        </Contents>
        <OpenButton>
          <button onClick={openModal}>견적 추가</button>
        </OpenButton>
      </Container>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <ContentsForm onSubmit={handleSubmit(onSubmitTable, onError)}>
          <div className="inputs">
            <textarea
              // type="text"
              placeholder="견적 제목에 들어갈 내용을 입력하세요"
              autoComplete="off"
              defaultValue={getValues("title")}
              {...register("title", {
                required: true,
              })}
            />
          </div>
          <div className="buttonWrapper">
            <button type="submit" className="tableSubmit">
              생성
            </button>
          </div>
        </ContentsForm>
      </Modal>
      <Modal
        isOpen={tableIsOpen}
        style={customStyles}
        onRequestClose={closeTableModal}
      >
        <ContentsForm onSubmit={handleSubmit(onSubmitTableUpdate, onError)}>
          <div className="inputs">
            <textarea
              // type="text"
              autoComplete="off"
              defaultValue={getValues("title")}
              {...register("title", {
                required: true,
              })}
            />
          </div>
          <div className="buttonWrapper">
            <button type="submit" className="tableSubmit">
              생성
            </button>
          </div>
        </ContentsForm>
      </Modal>
    </React.Fragment>
  );
}

export default RenaultContentsAdmin;
