import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import CryptoJS from "crypto-js";

function PrivateRoute({ type }) {
  const requestUrl = window.location.href;

  const loginKey = localStorage.getItem("allotter");
  if (!loginKey) {
    return <Navigate to={`/login?type=${type}&requestUrl=${requestUrl}`} />;
  } else {
    const salt = "allotter";
    const decodedKey = CryptoJS.AES.decrypt(loginKey, salt).toString(
      CryptoJS.enc.Utf8
    );

    if (type === "nocode") {
      if (decodedKey === "0703") {
        return <Outlet />;
      } else {
        return <Navigate to={`/login?type=${type}&requestUrl=${requestUrl}`} />;
      }
    } else if (type === "code") {
      if (decodedKey === "0124") {
        return <Outlet />;
      } else {
        return <Navigate to={`/login?type=${type}&requestUrl=${requestUrl}`} />;
      }
    } else if (type === "renault") {
      if (decodedKey === "0124") {
        return <Outlet />;
      } else {
        return <Navigate to={`/login?type=${type}&requestUrl=${requestUrl}`} />;
      }
    }

    // "common"
    else if (type === "common") {
      if (decodedKey === "0703" || decodedKey === "0124") {
        return <Outlet />;
      } else {
        return <Navigate to={`/login?type=${type}&requestUrl=${requestUrl}`} />;
      }
    }
  }
}

export default PrivateRoute;
