import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { queryKey } from "../utils/queryKey";
import { getTablesApi } from "../apis/table";

const Quotes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
  }
  #plusBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
`;
const Quote = styled.div`
  border-radius: 1.8rem;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
  padding: 1rem 1.5rem;
  font-size: ${(props) => props.theme.descriptionFontSize};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 6rem;
  @media screen and (max-width: 780px) {
    padding: 0.5rem;
    height: 4rem;
    border-radius: 0.5rem;
  }
  &.active {
    background-color: ${(props) => props.theme.keyColor};
    transition: 0.3s;
  }
  cursor: pointer;
  .titleAndPrice {
    display: flex;
    justify-content: space-between;
  }
  h3.title {
    font-weight: 600;
    font-size: ${(props) => props.theme.titleFontSize};
  }
  .price {
    strong {
      font-size: ${(props) => props.theme.titleFontSize};
      margin-right: 0.2rem;
      font-weight: 600;
    }
  }
  span.description {
    margin-top: 1rem;
    display: block;
    opacity: 0.6;
    word-break: keep-all;
  }
`;

function RenaultQuotes({
  table,
  btnActive,
  setBtnActive,
  selectedQuotes,
  setSelectedQuotes,
  totalPrice,
  setTotalPrice,
}) {
  // useQuery
  const { refetch } = useQuery([queryKey.tableKey.getTable], () =>
    getTablesApi("renault")
  );

  const toggleActive = (quote) => {
    if (btnActive.includes(quote.id)) {
      setBtnActive(btnActive.filter((item) => item !== quote.id));
      setSelectedQuotes(selectedQuotes.filter((item) => item.id !== quote.id));
      setTotalPrice(
        totalPrice -
          parseInt(
            selectedQuotes.filter((item) => item.id === quote.id)[0].price
          )
      );
    } else {
      setBtnActive([...btnActive, quote.id]);
      setSelectedQuotes([
        ...selectedQuotes,
        {
          id: quote.id,
          title: quote.title,
          price: quote.price,
          description: quote.description,
        },
      ]);
      setTotalPrice(totalPrice + parseInt(quote.price));
    }
  };

  return (
    <React.Fragment>
      <Quotes>
        {table?.quotes.map((quote) => (
          <Quote
            key={quote.id}
            onClick={() => toggleActive(quote)}
            className={btnActive.includes(quote.id) ? "active" : ""}
          >
            <div className="titleAndPrice">
              <h3 className="title">{quote.title}</h3>
              <p className="price">
                <strong>{quote.price}</strong> 원
              </p>
            </div>
            <span className="description">{quote.description}</span>
          </Quote>
        ))}
      </Quotes>
    </React.Fragment>
  );
}

export default RenaultQuotes;
