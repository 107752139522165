import React, { useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { FaXmark } from "react-icons/fa6";
import domtoimage from "dom-to-image-more";
import { saveAs } from "file-saver";
import { useMutation } from "react-query";
import { createLeadApi } from "../apis/lead";
import AWS from "aws-sdk";
import axios from "axios";

const Container = styled.div`
  padding: 1.5rem;
  background-color: #fff;
  width: 40rem;
  @media screen and (max-width: 780px) {
    width: 100vw;
  }
`;
const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
  h1 {
    font-size: ${(props) => props.theme.bigFontSize};
    font-weight: 600;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    scale: 1.5;
  }
`;
const Contents = styled.div``;
const DownloadBtn = styled.div`
  button {
    ${(props) => props.theme.buttonFormat}
    width: 100%;
    height: 3rem;
    font-weight: 600;
    margin-top: 2.5rem;
    border-radius: 2rem;
    &:hover {
      scale: 1;
    }
    display: ${(props) => (props.hideBtn ? "none" : "block")};
  }
`;
const HeadRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #000;
  color: #fff;
  padding: 0.5rem 0;
  margin-top: 1.5rem;
  h3 {
    text-align: center;
    font-weight: 600;
  }
  &.price {
    grid-template-columns: 1fr 1fr;
  }
`;
const ContentsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1rem 0;
  align-items: center;
  font-size: 0.85rem;
  border-bottom: 1px solid rgb(220, 220, 220);
  word-break: keep-all;
  /* margin-bottom: 1.5rem; */
  &.price {
    grid-template-columns: 1fr 1fr;
    strong {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
  p {
    text-align: center;
  }
  .receiver {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0.5rem;
    align-items: center;
    padding-right: 1rem;
    @media screen and (max-width: 780px) {
      display: flex;
      flex-direction: column;
      grid-row-gap: 0;
      padding-right: 0;
      .itemName {
        /* margin-bottom: 0.3rem; */
        font-weight: 600;
      }
      input {
        margin-bottom: 1rem;
        margin-left: 0;
        margin-top: 0rem;
      }
      .capture {
        margin-left: 0;
        margin-bottom: 0.1rem;
      }
    }
    .itemName {
      text-align: right;
      margin-right: 0.3rem;
      white-space: nowrap;
    }
    .itemContent {
      p {
        text-align: left;
      }
    }
    input {
      width: 10rem;
      /* margin-right: 0.5rem; */
      border: 1px solid rgb(200, 200, 200);
      outline: none;
      padding: 0.3rem;
      margin-left: 0.3rem;
      @media screen and (max-width: 780px) {
        width: 8rem;
      }
    }
    span.capture {
      margin-left: 0.3rem;
    }
  }
  &#quotes {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: none;
  }

  #signature {
    width: 4rem;
    height: 4rem;
    object-fit: contain;
  }
`;
const QuoteRow = styled.div`
  display: grid;
  padding: 0.7rem 0;
  border-bottom: 1px solid rgb(220, 220, 220);
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr;
  p {
    text-align: left;
    &.desc {
      text-align: center;
    }
    &.price {
      text-align: right;
      font-weight: 600;
    }
  }
`;
const Description = styled.div`
  margin-top: 1.5rem;
  p {
    margin-bottom: 0.2rem;
    font-size: 0.8rem;
    color: rgb(150, 150, 150);
  }
`;

function QuotesDocs({
  selectedQuotes,
  totalPrice,
  closeModal,
  type,
  name,
  email,
  phone,
  plusAlpha,
}) {
  const today = dayjs().format("YYYY-MM-DD");
  const todayTime = dayjs().format("YYYY-MM-DD HH:mm:ss");

  let requiredTime = 0;
  if (totalPrice < 3000) {
    requiredTime = 1.5;
  } else if (totalPrice < 5000) {
    requiredTime = 2;
  } else if (totalPrice < 7000) {
    requiredTime = 3;
  } else {
    requiredTime = "need";
  }

  // const [name, setName] = useState("");
  // const onChangeName = (e) => {
  //   setName(e.target.value);
  // };
  // const [email, setEmail] = useState("");
  // const onChangeEmail = (e) => {
  //   setEmail(e.target.value);
  // };
  // const [phone, setPhone] = useState("");
  // const onChangePhone = (e) => {
  //   setPhone(e.target.value);
  // };

  const { mutate: createLead } = useMutation(createLeadApi);

  // S3 관련
  // AWS.config.update({
  //   region: process.env.REACT_APP_AWS_REGION,
  //   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  //   secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  // });

  // const s3 = new AWS.S3();
  const fileName = `${name}_${todayTime}_${totalPrice}_견적서.png`;

  async function uploadToS3(blob) {
    const formData = new FormData();
    formData.append("file", blob);
    formData.append("fileName", fileName);

    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/quotes/upload/image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("S3 업로드 완료");

    // const params = {
    //   Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    //   Key: fileName,
    //   Body: blob,
    //   ContentType: "image/png",
    //   ACL: "public-read",
    // };

    // s3.upload(params, (err, data) => {
    //   if (err) {
    //     console.error("Error uploading data: ", err);
    //   } else {
    //     console.log("Successfully uploaded data to S3");
    //   }
    // });
  }

  // 이미지 다운로드
  const onDownloadBtn = () => {
    if (name === "" || email === "" || phone === "") {
      alert("수신인 정보를 입력해주세요.");
      return;
    } else {
      setHideBtn(true);
      setTimeout(() => {
        const fileLink = `${process.env.REACT_APP_AWS_S3_BASE_URL}/${fileName}`;

        domtoimage
          .toBlob(document.getElementById("downloadDom"))
          .then((blob) => {
            saveAs(blob, `${fileName}`);
            uploadToS3(blob); // S3 업로드
          });

        createLead({
          name,
          email,
          phone,
          type,
          fileLink,
        });
        setHideBtn(false);
      }, 100);

      console.log(hideBtn);
    }
  };

  const [hideBtn, setHideBtn] = useState(false);
  console.log({ type });

  return (
    <Container id="downloadDom">
      <Title>
        <h1>예상 견적서</h1>
        <button className="exit" onClick={closeModal}>
          <FaXmark />
        </button>
      </Title>
      <Contents>
        <div className="row">
          <HeadRow>
            <h3>날짜</h3>
            <h3>수신</h3>
            <h3>유효기한</h3>
          </HeadRow>
          <ContentsRow>
            <p>{today}</p>
            <div className="receiver">
              <div className="itemName">수신인명 :</div>
              <div className="itemContent">
                {hideBtn ? (
                  <span className="capture">{name}</span>
                ) : (
                  // <input
                  //   type="text"
                  //   // onChange={onChangeName}
                  //   defaultValue={name}
                  // />
                  <p>{name}</p>
                )}
              </div>
              <div className="itemName">이메일 :</div>
              <div className="itemContent">
                {hideBtn ? (
                  <span className="capture">{email}</span>
                ) : (
                  // <input
                  //   type="email"
                  //   // onChange={onChangeEmail}
                  //   defaultValue={email}
                  // />
                  <p>{email}</p>
                )}
              </div>
              <div className="itemName">연락처 :</div>
              <div className="itemContent">
                {hideBtn ? (
                  <span className="capture">{phone}</span>
                ) : (
                  // <input
                  //   type="text"
                  //   // onChange={onChangePhone}
                  //   defaultValue={phone}
                  // />
                  <p>{phone}</p>
                )}
              </div>
            </div>
            <p>견적일로부터 30일</p>
          </ContentsRow>
        </div>
        <div className="row">
          <HeadRow>
            <h3>사업자 번호</h3>
            <h3>상호</h3>
            <h3>날인</h3>
          </HeadRow>
          <ContentsRow>
            <p>{type === "renault" ? "131-81-96380" : "895-86-02579"}</p>
            <p>
              {type === "renault"
                ? "르노코리아자동차 남동정비사업소"
                : "주식회사 디바인스"}
            </p>
            <p>
              <img
                id="signature"
                src="/img/signature.png"
                alt={
                  type === "renault"
                    ? "르노코리아자동차 남동정비사업소"
                    : "주식회사 디바인스"
                }
              />
            </p>
          </ContentsRow>
        </div>
        <div className="row">
          <HeadRow>
            <h3>기능명</h3>
            <h3>설명</h3>
            <h3>구현비용</h3>
          </HeadRow>
          <ContentsRow id="quotes">
            {selectedQuotes.map((quote) => (
              <QuoteRow key={quote.id}>
                <p>{quote.title}</p>
                <p className="desc">{quote.description}</p>
                <p className="price">
                  <strong>{quote.price}</strong>
                  {type === "renault" ? "" : "만"} 원
                </p>
              </QuoteRow>
            ))}
            {plusAlpha && (
              <QuoteRow>
                <p>기타</p>
                <p className="desc">{plusAlpha}</p>
                <p className="price"></p>
              </QuoteRow>
            )}
          </ContentsRow>
        </div>
        <div className="row">
          <HeadRow className="price">
            <h3>{type !== "renault" && "개발 기간"}</h3>
            <h3>공급가액 (부가세)</h3>
          </HeadRow>
          <ContentsRow className="price">
            <p>
              {type === "renault" ? (
                ""
              ) : requiredTime === "need" ? (
                <strong>상담 필요</strong>
              ) : (
                <strong>{requiredTime}개월</strong>
              )}
            </p>
            <p>
              <strong>
                {totalPrice}
                {type === "renault" ? "" : "만"} 원
              </strong>{" "}
              ({Math.round(totalPrice * 0.1)}
              {type === "renault" ? "" : "만"} 원) {plusAlpha && "+ ɑ"}
            </p>
          </ContentsRow>
        </div>
        <Description>
          <p>• 상세 견적 산출 이전 단계인 대략적인 예상 견적입니다.</p>
          <p>
            • 무료 IA 컨설팅 및 상세 견적이 필요하실 경우, 홈페이지 내 문의하기
            버튼을 통해 Contact해 주세요! 감사합니다. :)
          </p>
          <p>• 개발 스택과 환경에 구애받지 마세요. 무엇이든지 구현해 냅니다.</p>
        </Description>
      </Contents>
      {!hideBtn && (
        <DownloadBtn>
          <button onClick={onDownloadBtn}>견적서 다운로드</button>
        </DownloadBtn>
      )}
    </Container>
  );
}

export default QuotesDocs;
