import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import {
  createQuotesApi,
  deleteQuotesApi,
  updateQuotesApi,
  updateQuotesOrderApi,
} from "../../apis/quotes";
import { queryKey } from "../../utils/queryKey";
import { getTablesApi } from "../../apis/table";
import Modal from "react-modal";

const Quotes = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  @media screen and (max-width: 780px) {
    display: flex;
    flex-direction: column;
  }
  #plusBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
`;
const UpOrDownButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.3rem;
  button {
    scale: 0.8;
    cursor: pointer;
  }
`;
const QuoteWrapper = styled.div``;
const Quote = styled.div`
  border-radius: 1.8rem;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.4);
  padding: 1rem 1.5rem;
  font-size: ${(props) => props.theme.descriptionFontSize};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 6rem;
  &:hover {
    scale: 1.05;
  }
  cursor: pointer;
  .titleAndPrice {
    display: flex;
    justify-content: space-between;
  }
  h3.title {
    font-weight: 600;
    font-size: ${(props) => props.theme.titleFontSize};
  }
  .price {
    strong {
      font-size: ${(props) => props.theme.titleFontSize};
      margin-right: 0.2rem;
      font-weight: 600;
    }
  }
  span.description {
    margin-top: 1rem;
    display: block;
    opacity: 0.6;
    word-break: keep-all;
  }
`;

const ContentsForm = styled.form`
  padding: 1rem;
  background-color: ${(props) => props.theme.bgColor};
  .inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  input,
  textarea {
    ${(props) => props.theme.inputFormat}
  }
  textarea {
    height: 200px;
    resize: none;
  }
  button {
    ${(props) => props.theme.buttonFormat}
    &:hover {
      scale: 1.05;
    }
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    :hover {
      scale: 1.05;
    }
    #deleteBtn {
      ${(props) => props.theme.buttonFormat}
      margin-right: 0.5rem;
      font-size: 1rem;
      &:hover {
        scale: 1.05;
      }
    }
  }
`;

function CodeQuotesAdmin({ table, customStyles }) {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const onError = (error, e) => {
    console.log(error, e);
    alert("모든 항목을 입력해주세요");
  };

  // useQuery
  const { refetch } = useQuery([queryKey.tableKey.getTable], () =>
    getTablesApi("code")
  );

  const [quotesIsOpen, setQuotesIsOpen] = useState(false);
  const openQuotesModal = () => {
    setQuotesIsOpen(true);
  };
  const closeQuotesModal = () => {
    setQuotesIsOpen(false);
    setValue("quotesTitle", "");
    setValue("description", "");
    setValue("price", "");
  };

  const { mutate: createQuote } = useMutation(createQuotesApi, {
    onSuccess: () => {
      refetch();
      closeQuotesModal();
    },
  });
  const onSubmitQuotes = (inputData) => {
    console.log("onSubmitQuotes");
    console.log({ inputData });
    createQuote({
      type: "code",
      title: inputData.quotesTitle,
      description: inputData.description,
      price: inputData.price,
      tableId: table.id,
    });
  };

  // 수정 및 삭제
  const [updateOpen, setUpdateOpen] = useState(false);
  const [updateId, setUpdateId] = useState(0);
  const openUpdateModal = () => {
    setUpdateOpen(true);
  };
  const closeUpdateModal = () => {
    setUpdateOpen(false);
    setValue("quotesTitle", "");
    setValue("description", "");
    setValue("price", "");
  };
  const { mutate: updateQuotes } = useMutation(updateQuotesApi, {
    onSuccess: () => {
      refetch();
      closeUpdateModal();
    },
  });
  const { mutate: deleteQuotes } = useMutation(deleteQuotesApi, {
    onSuccess: () => {
      refetch();
      closeUpdateModal();
    },
  });

  const onClickModify = ({ title, description, price, id }) => {
    setValue("quotesTitle", title);
    setValue("description", description);
    setValue("price", price);
    setUpdateId(id);
    openUpdateModal();
  };

  const onSubmitUpdate = (inputData) => {
    updateQuotes({
      type: "code",
      title: inputData.quotesTitle,
      description: inputData.description,
      price: inputData.price,
      id: updateId,
    });
  };

  // 순서 변경
  const { mutate: upOrDownQuotes } = useMutation(updateQuotesOrderApi, {
    onSuccess: () => {
      refetch();
    },
  });
  const onClickUp = (id) => {
    upOrDownQuotes({
      type: "code",
      id: id,
      upOrDown: "up",
    });
  };
  const onClickDown = (id) => {
    upOrDownQuotes({
      type: "code",
      id: id,
      upOrDown: "down",
    });
  };

  return (
    <React.Fragment>
      <Quotes>
        {table?.quotes.map((quote, i) => (
          <QuoteWrapper key={quote.id}>
            <UpOrDownButtons>
              <button
                onClick={() => {
                  onClickDown(quote.id);
                }}
                disabled={i === 0}
              >
                ◀
              </button>
              <button
                onClick={() => {
                  onClickUp(quote.id);
                }}
                disabled={i === table.quotes.length - 1}
              >
                ▶
              </button>
            </UpOrDownButtons>
            <Quote
              onClick={() => {
                onClickModify({
                  title: quote.title,
                  description: quote.description,
                  price: quote.price,
                  id: quote.id,
                });
              }}
            >
              <div className="titleAndPrice">
                <h3 className="title">{quote.title}</h3>
                <p className="price">
                  <strong>{quote.price}</strong>만 원
                </p>
              </div>
              <span className="description">{quote.description}</span>
            </Quote>
          </QuoteWrapper>
        ))}
        <Quote id="plusBtn" onClick={openQuotesModal}>
          <p>+</p>
        </Quote>
      </Quotes>
      <Modal
        isOpen={quotesIsOpen}
        style={customStyles}
        onRequestClose={closeQuotesModal}
      >
        <ContentsForm onSubmit={handleSubmit(onSubmitQuotes, onError)}>
          <div className="inputs">
            <input
              type="text"
              autoComplete="off"
              defaultValue={getValues("quotesTitle")}
              {...register("quotesTitle", {
                required: true,
              })}
              placeholder="항목명"
            />
            <input
              type="text"
              autoComplete="off"
              defaultValue={getValues("description")}
              {...register("description", {
                required: true,
              })}
              placeholder="상세 설명"
            />
            <input
              type="number"
              autoComplete="off"
              defaultValue={getValues("price")}
              {...register("price", {
                required: true,
              })}
              placeholder="가격 (단위 : 만원)"
            />
          </div>
          <div className="buttonWrapper">
            <button type="submit" className="tableSubmit">
              생성
            </button>
          </div>
        </ContentsForm>
      </Modal>
      <Modal
        isOpen={updateOpen}
        style={customStyles}
        onRequestClose={closeUpdateModal}
      >
        <ContentsForm onSubmit={handleSubmit(onSubmitUpdate, onError)}>
          <div className="inputs">
            <input
              type="text"
              autoComplete="off"
              defaultValue={getValues("quotesTitle")}
              {...register("quotesTitle", {
                required: true,
              })}
              placeholder="항목명"
            />
            <input
              type="text"
              autoComplete="off"
              defaultValue={getValues("description")}
              {...register("description", {
                required: true,
              })}
              placeholder="상세 설명"
            />
            <input
              type="number"
              autoComplete="off"
              defaultValue={getValues("price")}
              {...register("price", {
                required: true,
              })}
              placeholder="가격 (단위 : 만원)"
            />
          </div>
          <div className="buttonWrapper">
            <div
              id="deleteBtn"
              onClick={() => {
                if (
                  window.confirm(
                    `${getValues("quotesTitle")}을 삭제하시겠습니까?`
                  )
                ) {
                  deleteQuotes({ type: "code", id: updateId });
                }
              }}
            >
              삭제
            </div>
            <button type="submit" className="tableSubmit">
              수정
            </button>
          </div>
        </ContentsForm>
      </Modal>
    </React.Fragment>
  );
}

export default CodeQuotesAdmin;
