import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CodeHeader from "../components/CodeHeader";
import CodeContents from "../components/CodeContents";

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.bgColor};
`;
const Header = styled.div`
  margin-bottom: 3rem;
`;
const Contents = styled.div`
  width: 900px;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  background-color: #fff;
  min-height: 100vh;
  padding: 2.5rem 1rem 8rem;
  .openButton {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    :hover {
      scale: 1.05;
    }
  }
`;

const Tables = styled.div``;

function Code() {
  return (
    <Container>
      <Contents>
        <Header>
          <CodeHeader prod={true} />
        </Header>
        <Tables>
          <CodeContents prod={true} />
        </Tables>
      </Contents>
    </Container>
  );
}

export default Code;
