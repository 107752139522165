import React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getLeadsApi } from "../apis/lead";

const Container = styled.div`
  width: 50rem;
  margin: auto;
  @media screen and (max-width: 780px) {
    width: 100%;
  }
`;
const Inner = styled.div`
  background-color: #fff;
  padding: 3rem;
  min-height: 100vh;
  @media screen and (max-width: 780px) {
    padding: 1rem;
  }
`;
const Title = styled.div`
  h1 {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }
  margin-bottom: 2rem;
  @media screen and (max-width: 780px) {
    h1 {
      font-size: 1.5rem;
    }
  }
`;
const Contents = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 1rem;
  text-align: center;
  word-break: keep-all;
  li.head {
    font-weight: 900;
  }
  li > a {
    color: blue;
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 780px) {
    font-size: 0.8rem;
    li.head {
      white-space: nowrap;
    }
  }
`;

function Leads() {
  const { data: leads, isLoading } = useQuery(["leads"], getLeadsApi, {
    refetchOnWindowFocus: false,
  });
  console.log({ leads, isLoading });

  return (
    <Container>
      <Inner>
        <Title>
          <h1>견적서 다운로드 고객 명단</h1>
        </Title>
        <Contents>
          <li className="head">고객명</li>
          <li className="head">이메일</li>
          <li className="head">유형</li>
          <li className="head">연락처</li>
          <li className="head">견적서</li>
          <li className="head">생성일자</li>
          {leads &&
            leads?.map((lead, i) => (
              <React.Fragment key={i}>
                <li>{lead.name}</li>
                <li>{lead.email}</li>
                <li>{lead.type}</li>
                <li>{lead.phone}</li>
                <li>
                  <a href={lead.fileLink}>사진 조회</a>
                </li>
                <li>{lead.createdAt?.slice(0, 10)}</li>
              </React.Fragment>
            ))}
        </Contents>
      </Inner>
    </Container>
  );
}

export default Leads;
