import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

export const getPriceRangesApi = async ({ type }) => {
  return await (
    await axios.get(`${BASE_URL}/priceRange?type=${type}`)
  ).data;
};

export const createPriceRangeApi = async ({ price, type, requiredTime }) => {
  return await (
    await axios.post(`${BASE_URL}/priceRange`, {
      price,
      type,
      requiredTime,
    })
  ).data;
};

export const deletePriceRangeApi = async ({ id }) => {
  return await (
    await axios.delete(`${BASE_URL}/priceRange/${id}`)
  ).data;
};

export const updatePriceRangeApi = async ({ id, price, requiredTime }) => {
  return await (
    await axios.put(`${BASE_URL}/priceRange/${id}`, {
      price,
      requiredTime,
    })
  ).data;
};
