import React from "react";
import styled from "styled-components";
import {
  createPriceRangeApi,
  deletePriceRangeApi,
  getPriceRangesApi,
  updatePriceRangeApi,
} from "../apis/priceRange";
import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";

const Container = styled.div`
  margin: 2rem 0 3rem;
`;
const Title = styled.div`
  margin-bottom: 1rem;
  h2 {
    font-size: 1.3rem;
    font-weight: 600;
  }
`;
const Contents = styled.form``;
const PriceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  span {
    margin: 0 1rem;
  }
  input {
    width: 5rem;
    text-align: center;
  }
  #updateBtn {
    margin-right: 0.5rem;
  }
`;

function PriceRange({ type }) {
  // useForm
  const { register, handleSubmit, getValues, setValue } = useForm();

  const {
    data: priceRange,
    isLoading,
    error,
    refetch,
  } = useQuery("getPriceRange", () => getPriceRangesApi({ type }));
  console.log({ priceRange });
  // i번째 요소, i+1번째 요소들끼리 짝지어서 배열로 만들기
  // const priceRangePairs = [];
  // for (let i = 0; i < priceRange?.length; i += 1) {
  //   priceRangePairs.push(priceRange.slice(i, i + 2));
  // }

  // console.log({ priceRangePairs });

  const { mutate: deletePriceRange } = useMutation(deletePriceRangeApi, {
    onSuccess: () => {
      alert("삭제되었습니다.");
      refetch();
    },
  });
  const onClickDelete = (id) => {
    deletePriceRange({ id });
  };

  const { mutate: updatePriceRange } = useMutation(updatePriceRangeApi, {
    onSuccess: () => {
      alert("수정되었습니다.");
      refetch();
    },
  });
  const onClickUpdate = ({ id, price, requiredTime }) => {
    updatePriceRange({ id, price, requiredTime });
  };

  const { mutate: createPriceRange } = useMutation(createPriceRangeApi, {
    onSuccess: () => {
      alert("추가되었습니다.");
      setValue("price", "");
      setValue("requiredTime", "");
      refetch();
    },
  });

  const onSubmitCreate = (inputData) => {
    createPriceRange({
      type,
      price: inputData.price,
      requiredTime: inputData.requiredTime,
    });
  };

  return (
    <Container>
      <Title>
        <h2>가격 범위 설정 (단위 : 만원)</h2>
      </Title>
      <Contents onSubmit={handleSubmit(onSubmitCreate)}>
        {priceRange?.map((priceRangeEl, i) => (
          <PriceRow key={i}>
            <div className="priceList">
              <input
                type="text"
                defaultValue={priceRangeEl.price}
                {...register(`price${priceRangeEl.id}`)}
              />
              <span>~</span>
            </div>
            <div className="requiredTime">
              <input
                type="text"
                defaultValue={priceRangeEl.requiredTime}
                {...register(`requiredTime${priceRangeEl.id}`)}
              />
              <span>개월</span>
              <button
                id="updateBtn"
                onClick={() => {
                  onClickUpdate({
                    id: priceRangeEl.id,
                    price: getValues(`price${priceRangeEl.id}`),
                    requiredTime: getValues(`requiredTime${priceRangeEl.id}`),
                  });
                }}
              >
                수정
              </button>
              <button
                id="deleteBtn"
                onClick={() => {
                  onClickDelete(priceRangeEl.id);
                }}
              >
                삭제
              </button>
            </div>
          </PriceRow>
        ))}
        <PriceRow>
          <div className="priceList">
            <input type="text" {...register("price")} />
            <span>~</span>
          </div>
          <div className="requiredTime">
            <input type="text" {...register("requiredTime")} />
            <span>개월</span>
            <button>추가</button>
          </div>
        </PriceRow>
      </Contents>
    </Container>
  );
}

export default PriceRange;
