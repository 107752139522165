import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { queryKey } from "../utils/queryKey";
import {
  deleteHeaderApi,
  getHeadersApi,
  updateHeaderApi,
  updateHeaderOrderApi,
} from "../apis/header";
import styled from "styled-components";
import Modal from "react-modal";
import { useForm } from "react-hook-form";

const Container = styled.div`
  background-color: rgb(252, 239, 238);
  padding: 1.5rem 3rem;
  border-radius: 2.5rem;
  @media screen and (max-width: 780px) {
    padding: 0.8rem;
    border-radius: 1rem;
  }
`;
const Header = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  @media screen and (max-width: 780px) {
    margin-bottom: 0.5rem;
  }
  font-size: ${(props) => props.theme.descriptionFontSize};
  &:last-child {
    margin-bottom: 0;
  }
  .modify,
  .delete {
    ${(props) => props.theme.buttonFormat}
  }
  button.delete {
    margin-left: 0.5rem;
  }
  .buttons {
    display: flex;
    min-width: max-content;
  }
`;
const HeaderForm = styled.form`
  padding: 1rem;
  background-color: ${(props) => props.theme.bgColor};
  .inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  input,
  textarea {
    ${(props) => props.theme.inputFormat}
  }
  textarea {
    height: 200px;
    resize: none;
  }
  button {
    ${(props) => props.theme.buttonFormat}
    &:hover {
      scale: 1.05;
    }
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    :hover {
      scale: 1.05;
    }
  }
`;

const UpOrDownButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.3rem;
  button {
    scale: 0.8;
    cursor: pointer;
  }
`;

function NocodeHeader({ prod }) {
  // Modal
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: `50vw`,
      // height: `50vh`,
      padding: 0,
      borderColor: "#000",
      backgroundColor: "rgb(245,243,243)",
    },
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    setValue("content", "");
  };

  // react-hook-form
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  // useQuery
  const {
    data: headers,
    isLoading,
    refetch,
  } = useQuery([queryKey.headerKey.getHeader], () => getHeadersApi("nocode"));
  console.log(headers);

  const { mutate: updateHeader } = useMutation(updateHeaderApi, {
    onSuccess: () => {
      refetch();
      closeModal();
    },
  });
  const { mutate: deleteHeader } = useMutation(deleteHeaderApi, {
    onSuccess: () => {
      refetch();
    },
  });

  const onSubmitHeader = (inputData) => {
    console.log(inputData);
    updateHeader({
      type: "nocode",
      content: inputData.content,
      order: inputData.order,
      id: inputData.id,
    });
  };

  const onError = (error, e) => {
    // console.log(error);
    alert("내용을 입력해주세요");
  };

  const onClickModify = (content, order, id) => {
    setValue("content", content);
    setValue("order", order);
    setValue("id", id);
    openModal();
  };

  // up or down
  const { mutate: upOrDownHeader } = useMutation(updateHeaderOrderApi, {
    onSuccess: () => {
      refetch();
    },
  });
  const onClickUp = (id) => {
    upOrDownHeader({
      type: "nocode",
      id: id,
      upOrDown: "up",
    });
  };
  const onClickDown = (id) => {
    upOrDownHeader({
      type: "nocode",
      id: id,
      upOrDown: "down",
    });
  };

  return (
    <>
      <Container>
        <ul>
          {headers?.map((header) => (
            <React.Fragment key={header.id}>
              <Header>
                <p>✔️ {header.content}</p>
                {prod === true ? (
                  <></>
                ) : (
                  <div className="buttons">
                    <button
                      className="modify"
                      onClick={() => {
                        onClickModify(header.content, header.order, header.id);
                      }}
                    >
                      수정
                    </button>
                    <button
                      className="delete"
                      onClick={() => {
                        if (
                          window.confirm(
                            `${header.order}번째 헤더를 삭제하시겠습니까?`
                          )
                        ) {
                          deleteHeader({ type: "nocode", id: header.id });
                        }
                      }}
                    >
                      삭제
                    </button>
                    <UpOrDownButtons>
                      <button
                        onClick={() => {
                          onClickDown(header.id);
                        }}
                        disabled={header.order === 1}
                      >
                        ▲
                      </button>
                      <button
                        onClick={() => {
                          onClickUp(header.id);
                        }}
                        disabled={header.order === headers.length}
                      >
                        ▼
                      </button>
                    </UpOrDownButtons>
                  </div>
                )}
              </Header>
            </React.Fragment>
          ))}
        </ul>
      </Container>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <HeaderForm onSubmit={handleSubmit(onSubmitHeader, onError)}>
          <div className="inputs">
            <textarea
              // type="text"
              placeholder="헤더에 들어갈 내용을 입력하세요"
              autoComplete="off"
              defaultValue={getValues("content")}
              {...register("content", {
                required: true,
              })}
            />
          </div>
          <div className="buttonWrapper">
            <button type="submit" className="headerSubmit">
              수정
            </button>
          </div>
        </HeaderForm>
      </Modal>
    </>
  );
}

export default NocodeHeader;
