import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import CryptoJS from "crypto-js";

const Container = styled.div`
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 2rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10rem;
    input {
      ${(props) => props.theme.inputFormat}
      min-width: 15rem;
    }
  }
`;

function Login() {
  const [searchParams, setSearchParams] = useSearchParams();
  const requestUrl = searchParams.get("requestUrl");
  const type = searchParams.get("type");
  const navigate = useNavigate();

  const onError = (error, e) => {
    alert("비밀번호를 입력해주세요");
  };

  // 로그인 관련
  const salt = "allotter";
  // const password = type === "nocode" ? "0703" : "0124";
  let password = "";
  if (type === "nocode") {
    password = "0703";
  } else if (type === "code") {
    password = "0124";
  } else if (type === "renault") {
    password = "0124";
  } else if (type === "common") {
    password = "0703";
  }

  const encryptedPw = CryptoJS.AES.encrypt(password, salt).toString();

  const { register, handleSubmit } = useForm();
  const onSubmitPw = (inputData) => {
    if (inputData.password === password) {
      localStorage.setItem("allotter", encryptedPw);
      if (requestUrl) {
        return (window.location = requestUrl);
      } else {
        return navigate("/code");
      }
    } else {
      if (type === "common" && inputData.password === "0124") {
        localStorage.setItem("allotter", encryptedPw);
        if (requestUrl) {
          return (window.location = requestUrl);
        } else {
          return navigate("/admin/leads");
        }
      }
      return alert("비밀번호가 틀렸습니다");
    }
  };

  return (
    <Container>
      <div className="title">
        <h1>로그인 페이지</h1>
        <p>비밀번호를 입력하세요</p>
      </div>
      <form className="form" onSubmit={handleSubmit(onSubmitPw, onError)}>
        <input
          type="password"
          placeholder="비밀번호"
          {...register("password", {
            required: true,
          })}
        />
      </form>
    </Container>
  );
}

export default Login;
