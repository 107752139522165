import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { queryKey } from "../utils/queryKey";
import {
  createTableApi,
  deleteTableApi,
  getTablesApi,
  updateTableApi,
  updateTableOrderApi,
} from "../apis/table";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createQuotesApi } from "../apis/quotes";
import RenaultQuotes from "./RenaultQuotes";
import QuotesDocs from "./QuotesDocs";

const Container = styled.div``;
const Contents = styled.div``;
const Table = styled.div`
  margin-bottom: 3rem;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  h2 {
    font-size: ${(props) => props.theme.bigFontSize};
    font-weight: 600;
    margin-right: 0.5rem;
  }
  .modify,
  .delete {
    ${(props) => props.theme.buttonFormat}
  }
  .buttons {
    display: flex;
    min-width: max-content;
  }
  button.delete {
    margin-left: 0.3rem;
  }
`;
const Footer = styled.footer`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${(props) => props.theme.keyColor};
  border-radius: 3rem;
  padding: 1rem 2rem;
  width: 900px;
  left: 0;
  right: 0;
  bottom: 0.5rem;
  margin: auto;
  background-color: #fff;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (max-width: 780px) {
    border-radius: 1rem;
    width: 98%;
    padding: 1rem;
  }
  h2.title {
    font-size: ${(props) => props.theme.titleFontSize};
    font-weight: 600;
    margin-bottom: 0.7rem;
  }
  strong {
    font-size: ${(props) => props.theme.titleFontSize};
    font-weight: 600;
    margin-right: 0.2rem;
  }
  .description {
    font-size: ${(props) => props.theme.descriptionFontSize};
    display: flex;
    align-items: flex-end;
    .desc {
      opacity: 0.6;
      margin-left: 0.1rem;
    }
  }
  button.showQuotes {
    ${(props) => props.theme.buttonFormat}
    border-radius: 2rem;
    padding: 0.8rem 1rem;
    &:hover {
      scale: 1;
    }
    @media screen and (max-width: 780px) {
      border-radius: 0.5rem;
      padding: 0.5rem 0.8rem;
    }
  }
`;

function RenaultContents() {
  const {
    setValue,
    formState: { errors },
  } = useForm();

  // useQuery
  const { data: tables } = useQuery([queryKey.tableKey.getTable], () =>
    getTablesApi("renault")
  );
  // console.log({ tables });

  const [btnActive, setBtnActive] = useState([]);
  const [selectedQuotes, setSelectedQuotes] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    console.log({ btnActive, selectedQuotes, totalPrice });
  }, [btnActive, selectedQuotes, totalPrice]);

  // 모달 관련
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // width: `40rem`,
      // height: `50vh`,
      padding: 0,
      border: "none",
      backgroundColor: "rgb(245,243,243)",
      overflow: "auto",
      maxHeight: "95%",
      overflowY: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    },
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  let requiredTime = 0;
  if (totalPrice === 0) {
    requiredTime = 0;
  } else if (totalPrice < 3000) {
    requiredTime = 1.5;
  } else if (totalPrice < 5000) {
    requiredTime = 2;
  } else if (totalPrice < 7000) {
    requiredTime = 3;
  } else {
    requiredTime = "need";
  }

  return (
    <React.Fragment>
      <Container>
        <Contents>
          {tables?.map((table) => (
            <Table key={table.id}>
              <Title>
                <h2>{table.title}</h2>
              </Title>
              <RenaultQuotes
                table={table}
                btnActive={btnActive}
                setBtnActive={setBtnActive}
                selectedQuotes={selectedQuotes}
                setSelectedQuotes={setSelectedQuotes}
                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
              />
            </Table>
          ))}
        </Contents>
        <Footer>
          <div className="left">
            <h2 className="title">예상 견적</h2>
            <span className="description">
              <strong>{totalPrice}</strong> 원
              {/* <p className="desc">
                (예상 기간{" "}
                {requiredTime === "need" ? (
                  <>상담 필요</>
                ) : (
                  <>{requiredTime}개월</>
                )}
                )
              </p> */}
            </span>
          </div>
          <div className="right">
            <button className="showQuotes" onClick={openModal}>
              견적서 보기
            </button>
          </div>
        </Footer>
      </Container>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
        // overlayClassName="overlay"
        // className="modal"
      >
        <div id="modal-scrollable" className="modal-scrollable">
          <QuotesDocs
            selectedQuotes={selectedQuotes}
            totalPrice={totalPrice}
            closeModal={closeModal}
            type="renault"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default RenaultContents;
