import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

export const createQuotesApi = async ({
  type,
  tableId,
  title,
  description,
  price,
}) => {
  return await (
    await axios.post(`${BASE_URL}/quotes/${type}`, {
      tableId,
      title,
      description,
      price,
    })
  ).data;
};

export const updateQuotesApi = async ({
  type,
  title,
  description,
  price,
  id,
}) => {
  return await (
    await axios.put(`${BASE_URL}/quotes/${type}/${id}`, {
      title,
      description,
      price,
    })
  ).data;
};

export const deleteQuotesApi = async ({ type, id }) => {
  return await (
    await axios.delete(`${BASE_URL}/quotes/${type}/${id}`)
  ).data;
};

export const updateQuotesOrderApi = async ({ type, id, upOrDown }) => {
  return await (
    await axios.put(`${BASE_URL}/quotes/${type}/${id}/order/${upOrDown}`)
  ).data;
};
