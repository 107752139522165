import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

export const getLeadsApi = async () => {
  return await (
    await axios.get(`${BASE_URL}/lead`)
  ).data;
};

export const createLeadApi = async ({ name, email, type, fileLink, phone }) => {
  return await (
    await axios.post(`${BASE_URL}/lead`, {
      name,
      email,
      type,
      fileLink,
      phone,
    })
  ).data;
};
