import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

export const getHeadersApi = async (type) => {
  return await (
    await axios.get(`${BASE_URL}/header/${type}`)
  ).data;
};

export const createHeaderApi = async ({ type, content }) => {
  return await (
    await axios.post(`${BASE_URL}/header/${type}`, { content })
  ).data;
};

export const updateHeaderApi = async ({ type, id, content, order }) => {
  return await (
    await axios.put(`${BASE_URL}/header/${type}/${id}`, { content, order })
  ).data;
};

export const deleteHeaderApi = async ({ type, id }) => {
  return await (
    await axios.delete(`${BASE_URL}/header/${type}/${id}`)
  ).data;
};

export const updateHeaderOrderApi = async ({ type, id, upOrDown }) => {
  return await (
    await axios.put(`${BASE_URL}/header/${type}/${id}/order/${upOrDown}`)
  ).data;
};
