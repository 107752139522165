import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { queryKey } from "../utils/queryKey";
import { createHeaderApi, getHeadersApi } from "../apis/header";
import RenaultHeader from "../components/RenaultHeader";
import RenaultContentsAdmin from "../components/admin/RenaultContentsAdmin";

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.bgColor};
`;
const Header = styled.div``;
const OpenButton = styled.button`
  ${(props) => props.theme.buttonFormat}
`;
const Contents = styled.div`
  width: 900px;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  background-color: #fff;
  min-height: 100vh;
  padding: 2.5rem 1rem;
  .openButton {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    :hover {
      scale: 1.05;
    }
  }
`;
const HeaderForm = styled.form`
  padding: 1rem;
  background-color: ${(props) => props.theme.bgColor};
  .inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  input,
  textarea {
    ${(props) => props.theme.inputFormat}
  }
  textarea {
    height: 200px;
    resize: none;
  }
  button {
    ${(props) => props.theme.buttonFormat}
    :hover {
      scale: 1.05;
    }
  }
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    :hover {
      scale: 1.05;
    }
  }
`;

const Tables = styled.div``;

function AdminRenault() {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: `50vw`,
      // height: `50vh`,
      padding: 0,
      borderColor: "#000",
      backgroundColor: "rgb(245,243,243)",
    },
  };
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const { data: headers, refetch } = useQuery(
    [queryKey.headerKey.getHeader],
    () => getHeadersApi("renault")
  );

  const queryClient = useQueryClient();
  const { mutate: createHeader } = useMutation(createHeaderApi, {
    onSuccess: () => {
      refetch();
      closeModal();
      setValue("content", "");
    },
  });

  const onSubmitHeader = (inputData) => {
    console.log(inputData);
    createHeader({
      type: "renault",
      content: inputData.content,
    });
  };

  const onError = (error, e) => {
    alert("모든 항목을 입력해주세요");
  };

  return (
    <Container>
      <Contents>
        <Header>
          <RenaultHeader />
          <div className="openButton">
            <OpenButton onClick={openModal}>헤더 추가</OpenButton>
          </div>
        </Header>
        <Tables>
          <RenaultContentsAdmin />
        </Tables>
      </Contents>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <HeaderForm onSubmit={handleSubmit(onSubmitHeader, onError)}>
          <div className="inputs">
            <textarea
              // type="text"
              placeholder="헤더에 들어갈 내용을 입력하세요"
              autoComplete="off"
              {...register("content", {
                required: true,
              })}
            />
          </div>
          <div className="buttonWrapper">
            <button type="submit" className="headerSubmit">
              생성
            </button>
          </div>
        </HeaderForm>
      </Modal>
    </Container>
  );
}

export default AdminRenault;
