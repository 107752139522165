import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

export const getTablesApi = async (type) => {
  return await (
    await axios.get(`${BASE_URL}/table/${type}`)
  ).data;
};

export const createTableApi = async ({ type, title }) => {
  return await (
    await axios.post(`${BASE_URL}/table/${type}`, { title })
  ).data;
};

export const updateTableApi = async ({ type, id, title }) => {
  return await (
    await axios.put(`${BASE_URL}/table/${type}/${id}`, { title })
  ).data;
};

export const deleteTableApi = async ({ type, id }) => {
  return await (
    await axios.delete(`${BASE_URL}/table/${type}/${id}`)
  ).data;
};

export const updateTableOrderApi = async ({ type, id, upOrDown }) => {
  return await (
    await axios.put(`${BASE_URL}/table/${type}/${id}/order/${upOrDown}`)
  ).data;
};
